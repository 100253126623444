<template>
  <div class="app-container">
    <CrudTable entity="Domain" :columns="columns" :generate-summary="generateSummary" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [`name`, `description`]
    };
  },
  methods: {
    generateSummary({ name, description }) {
      let summary = `${name}`;
      if (description) {
        summary += ` (${description})`;
      }
      return summary;
    }
  }
};
</script>
